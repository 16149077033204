<template>
    <multiselect
        :id="id"
        v-model="selectedOptions"
        :class="selectClass"
        :label="label"
        :track-by="trackBy"
        :placeholder="placeholder"
        open-direction="bottom"
        :options="options"
        :multiple="multiple"
        :searchable="searchable"
        :loading="isLoading"
        :options-limit="perPage"
        :limit="3"
        :limit-text="limitText"
        :max-height="600"
        :hide-selected="hideSelected"
        :disabled="disabled"
        :select-label="''"
        :selected-label="getI18n('MULTISELECT', 'selected_label')"
        :deselect-label="''"
        :close-on-select="!keepOpenOnSelect"
        :allow-empty="allowEmpty"
        :preselect-first="preselectFirst"
        aria-describedby="input-1-live-feedback"
    >
        <span slot="noResult">
            {{ getI18n('ERROR_CODES', 'search_not_found') }}
        </span>
        <template v-if="totalOptions > perPage" slot="afterList">
            <span class="resultLimit">
                {{ getI18n('ERROR_CODES', 'too_many_results') }}
            </span>
        </template>
        <template slot="noOptions">
            {{ getI18n('ERROR_CODES', 'empty_selector') }}
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: null,
        },

        searchable: {
            type: Boolean,
            default: false,
        },

        selectClass: {
            type: String,
            default: '',
        },

        optionsToRemoveList: {
            type: Array,
            default: () => [],
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        hideSelected: {
            type: Boolean,
            default: false,
        },

        keepOpenOnSelect: {
            type: Boolean,
            default: false,
        },

        trackBy: {
            type: String,
            default: 'id',
        },

        label: {
            type: String,
            default: 'name',
        },

        searchLabel: {
            type: String,
            default: null,
        },

        limitText: {
            type: Function,
            default: function (count) {
                return `${this.$options.filters.firstToLower(
                    this.getI18n('COMMON.and')
                )} ${count} ${this.$options.filters.firstToLower(
                    this.getI18nModified({
                        prefix: 'COMMON',
                        suffix: 'other_options',
                        modifier: count,
                    })
                )}`
            },
        },

        parameters: {
            type: Object,
            default: () => ({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            }),
        },

        options: {
            type: Array,
            default: () => [],
        },

        allowEmpty: {
            type: Boolean,
            // eslint-disable-next-line vue/no-boolean-default
            default: true,
        },

        preselectFirst: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            isLoading: false,
            totalOptions: 0,
            perPage: this.parameters.per_page || 1000,
        }
    },
    computed: {
        selectedOptions: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        placeholder() {
            return this.searchable
                ? this.getI18n('PLACEHOLDERS', 'type_to_search')
                : this.getI18n('PLACEHOLDERS', 'select_an_option')
        },
    },
}
</script>
