<template>
    <form-modal
        id="dataModal"
        ref="modal"
        :title="importTitle"
        :in-editing="(step !== 0 && step !== 1)"
        :size="sizeModal"
        :handle-hide="cleanModal"
        :handle-open="resetValidation"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <confirmation-form-modal
                ref="confirmationFormModal"
                :title="importTitle"
                :operation="'import'"
            />
            <div v-if="step === 0">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group
                                :label="i18nImportType"
                                class="required label-pdv"
                                label-for="modal-type"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                            >
                                <translatedMultiselect
                                    v-model="$v.csv.type.$model"
                                    :options="importTypesFiltered"
                                    :select-class="validationClass($v.csv.type)"
                                    @blur="$v.csv.type.$touch"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
            <div v-else-if="step === 1 || !csvValid">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group
                                :label="i18nFileHasHeader"
                                label-for="has-header"
                                class="label-pdv">
                                <b-form-checkbox
                                    id="has-header"
                                    v-model="csv.has_headers"
                                    size="lg"
                                    switch
                                />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="csv.type">
                            <b-form-group
                                :label="i18nSpreadsheetTemplate"
                                label-for="template-download"
                                class="label-pdv">
                                <a :href="`files/importJob/${csv.type.value}.zip`" download>
                                    <i class="fe-download mr-1" />
                                    {{ i18nDownloadFile }}
                                </a>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                                :label="i18nFile"
                                label-for="csv"
                                class="required label-pdv">
                                <div class="input-bar d-flex">
                                    <b-form-file
                                        ref="csv"
                                        v-model="$v.csv.file.$model"
                                        required
                                        type="file"
                                        :accept="fileMimeTypes.toString()"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getI18n('COMMON', 'no_file_chosen')"
                                        :state="validateField('file', 'csv')"
                                        @blur="$v.csv.file.$touch"
                                        @input="fileChange"
                                    />
                                </div>
                                <b-form-invalid-feedback :state="validateField('file', 'csv')">
                                    {{ getI18n('ERROR_CODES', 'invalid_file') }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
            <div v-else-if="step === 2 && csvValid">
                <field-map
                    ref="fieldMap"
                    v-model="csv.fields_mapped"
                    :first-row="csv.preview[0]"
                    :fields-to-map="csv.fields"
                />
            </div>
            <div v-else-if="step === 3 && csvValid">
                <topic-header
                    :caption="i18nPreview"
                    :subcaption="i18nTenLines"
                    icon="fe-file-text"
                />
                <div class="table-responsive">
                    <vuetable
                        ref="mapTable"
                        :fields="fields"
                        :api-mode="false"
                        :data="mappedPreview"
                        class="table table-centered table-hover mb-0">
                        <template slot="row" slot-scope="props">
                            {{ props.rowIndex + 1 }}
                        </template>
                    </vuetable>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div v-if="step === 0">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    @onClick="handleCancel"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    @onClick="goToFileSelection"
                />
            </div>
            <div v-if="step === 1">
                <back-button
                    v-if="availableTypes.length > 0"
                    ref="backButton"
                    child-class="col-sm-4 float-left"
                    @onClick="goToTypeSelection"
                />
                <cancel-button
                    v-else
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    @onClick="handleCancel"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    @onClick="goToMapping"
                />
            </div>
            <div v-else-if="step === 2 && csvValid">
                <back-button
                    ref="backButton"
                    child-class="col-sm-4 float-left"
                    @onClick="goToFileSelection"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    @onClick="buildCsv"
                />
            </div>
            <div v-else-if="step === 3 && csvValid">
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON', 'import')"
                    :busy="loadingSubmit"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import mimeTypes from 'mime-types'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import ImportJobService from '@src/services/ImportJobService'
import translatedMultiselect from '@components/translatedMultiselect'
import FieldMap from '@components/FieldMap'
import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import BackButton from '@/src/components/BackButton'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import TopicHeader from '@src/components/TopicHeader.vue'
import csvFields from '@src/mixins/csvFields'
import csvParse from '@src/mixins/csvParse'
import validation from '@src/mixins/validation'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import swalFeedback from '@src/mixins/swalFeedback.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'IMPORT_JOBS'

export default {
    components: {
        FieldMap,
        translatedMultiselect,
        Vuetable,
        FormModal,
        ConfirmationFormModal,
        BackButton,
        CancelButton,
        SaveButton,
        TopicHeader
    },
    mixins: [
        csvFields,
        csvParse,
        validation,
        vuetableFormatters,
        swalFeedback
    ],
    props: {
        csvType: {
            type: String,
            required: true,
        },
        availableTypes: {
            type: Array,
            required: false,
            default: () => [],
        },
        title: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            csv: {
                type: null,
                file: null,
                headers: null,
                has_headers: true,
                delimiter: ',',
                fields: {},
                parsed: {},
                preview: {},
                fields_mapped: {},
            },
            fieldsByType: {},
            mappedPreview: null,
            fileMimeTypes: [
                'text/csv',
                'text/x-csv',
                'application/vnd.ms-excel',
                'text/plain',
            ],
            step: 1,
            size: 'md',
            loading: false,
            loadingSubmit: false,
            fields: null,
        }
    },
    validations: {
        csv: {
            file: {
                required,
                isValidMime(value) {
                    if (!value) {
                        return true
                    }
                    const file = value

                    const mimeType =
                        file.type === ''
                            ? mimeTypes.lookup(file.name)
                            : file.type

                    return this.fileMimeTypes.indexOf(mimeType) > -1
                },
                isValidFileSize(value) {
                    if (!value) {
                        return true
                    }
                    const fileSizeinKb =
                        Math.round((value.size / 1024) * 100) / 100
                    return fileSizeinKb <= 5000
                },
            },
            type: {
                required,
                minLength: minLength(1),
            },
        },
    },
    computed: {
        importTitle() {
            if (this.step === 0 || this.availableTypes.length === 0) {
                return this.title || this.getI18n(i18nKey, 'TITLES.import_job')
            }

            return `${this.getI18n(i18nKey, 'TITLES.import_job')}${this.csv.type ? `: ${this.csv.type.text}` : ''}`
        },
        i18nImportJob() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.import_job',
                modifier: 2,
            })
        },
        i18nFileHasHeader() {
            return this.getI18n(i18nKey, 'TITLES.has_header')
        },
        i18nImportType() {
            return this.getI18n(i18nKey, 'TITLES.import_type')
        },
        i18nFile() {
            return `${this.getI18n(i18nCommon, 'file')} (${this.i18nAllowedFormats})`
        },
        csvValid() {
            return !this.$v.csv.$invalid
        },
        i18nPreview() {
            return this.getI18n(i18nCommon, 'preview')
        },
        i18nTenLines() {
            return this.getI18n(i18nKey, 'TITLES.ten_lines')
        },
        i18nAllowedFormats() {
            return this.getI18n(i18nKey, 'TITLES.allowed_formats')
        },
        i18nSpreadsheetTemplate() {
            return this.getI18n(i18nCommon, 'spreadsheet_template')
        },
        i18nDownloadFile() {
            return `${this.getI18n(i18nCommon, 'download')} ${this.getI18n(i18nCommon, 'file')}`
        },
        importTypesFiltered() {
            return this.availableTypes.length > 0 ? this.importTypes.filter(type => this.availableTypes.includes(type.value)) : this.importTypes
        },
        sizeModal() {
            if (this.step === 0 || this.step === 1) {
                return 'md'
            }

            return 'xl'
        },
    },

    watch: {
        'csv.type'(newVal) {
            if (newVal && newVal.value) {
                this.fieldsByType = this.importFields[newVal.value]
                this.fields = Object.keys(this.fieldsByType).map(
                    (key, index) => {
                        return {
                            name: key,
                            title: this.fieldsByType[key].label,
                        }
                    }
                )
                this.fields.unshift({
                    name: 'row',
                    title: this.getI18n('COMMON', 'line'),
                })
            } else {
                this.fieldsByType = null
                this.fields = null
            }
        },
    },
    methods: {
        async goToMapping() {
            this.$v.csv.$touch()

            if (this.csvValid) {
                this.fields = Object.keys(this.fieldsByType).map((key, index) => {
                    return {
                        name: key,
                        title: this.fieldsByType[key].label,
                    }
                })
                this.fields.unshift({
                    name: 'row',
                    title: this.getI18n('COMMON', 'line'),
                })

                this.csv.fields = this.createFieldsToMap(this.fieldsByType)
                this.loadCsv(this.csv.file).then(
                    ({ csvPreview, fullCsv, delimiter }) => {
                        this.csv.delimiter = delimiter
                        this.csv.preview = csvPreview
                        this.csv.parsed = fullCsv
                        this.mapByName(
                            this.csv.preview[0],
                            this.csv.fields,
                            this.csv.fields_mapped
                        )
                        this.step = 2
                    }
                )
            }
        },
        goToTypeSelection() {
            this.cleanModal()
            this.step = 0
        },
        goToFileSelection() {
            this.cleanModal()
            this.step = 1
        },
        buildCsv() {
            this.loading = true
            if (this.$refs.fieldMap.validateForm()) {
                this.mappedPreview = this.mapJson(this.csv.preview, this.csv.fields_mapped, this.csv.has_headers)
                this.step = 3
            }
            this.loading = false
        },
        fileChange() {
            if (this.$refs.fieldMap) {
                this.$refs.fieldMap.refreshTable()
            }
        },
        cleanFile() {
            this.csv.file = null
            setTimeout(() => {
                this.resetValidation()
            }, 0)
        },
        showModal() {
            this.fieldsByType = null
            this.step = this.availableTypes.length > 0 ? 0 : 1
            if (this.csvType) {
                this.csv.type = this.importTypes.find((type) => this.csvType === type.value) || null
            }

            this.cleanModal()
            this.$refs.modal.show()
        },

        hideModal() {
            this.$refs.modal.hide()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.mappedPreview = null
            this.loadingSubmit = false
            Object.assign(this.csv, {
                file: null,
                headers: null,
                has_headers: true,
                delimiter: ',',
                fields: {},
                parsed: {},
                preview: {},
                fields_mapped: {},
            })
            this.resetValidation()
        },

        handleCancel() {
            if (this.step > 1) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return
            }

            this.csv.type = null
            this.cleanModal()
            this.resetValidation()
            this.hideModal()
        },

        async handleOk() {
            this.$v.csv.$touch()
            if (this.csvValid && !this.loadingSubmit) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return
            }

            this.loadingSubmit = true

            const csvIimport = {
                ...this.csv,
                type: this.csv.type.value,
            }

            const response = await ImportJobService.createCsv(csvIimport)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.resetValidation()
                this.hideModal()
                this.$router.push({ name: 'importJobs', params: { newJobCreated: true } })
            }

            if (statusCode === '417') {
                const suffix = `ERRORS.${response.data.message}`
                this.operationUnsuccessful(i18nKey, suffix, 'create')
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.loadingSubmit = false
        }
    },
}
</script>
