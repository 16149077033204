<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nTimezone"
                            label-for="filter-timezone"
                            class="label-pdv">
                            <multi-select
                                id="filter-timezone"
                                v-model="userFilterModal.timezone"
                                :options="timezones"
                                track-by="id"
                                label="label"
                                :searchable="true"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nSituation"
                            label-for="filter-active"
                            class="label-pdv">
                            <multi-select
                                id="filter-active"
                                v-model="userFilterModal.active"
                                :options="userStatuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nRole"
                            label-for="filter-role"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-role'"
                                ref="roleMultiselect"
                                v-model="userFilterModal.roles"
                                :service="'roles'"
                                label="alias"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterRoleParameters"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nStateAndCity"
                            label-for="filter-state-city"
                            class="label-pdv">
                            <treeselect
                                :id="'filter-state-city'"
                                ref="filterStateCity"
                                v-model="userFilterModal.statesAndCities"
                                :options="statesWithCities"
                                :multiple="true"
                                :placeholder="getI18n('PLACEHOLDERS', 'type_to_search')"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import multiSelect from '@src/components/multiSelect'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import timezones from '@assets/resources/timezones'
import Treeselect from '@riophae/vue-treeselect'
import statesWithCities from '@src/assets/resources/statesWithCities'
import userStatuses from '@/src/mixins/userStatuses'

const i18nKey = 'JUSTIFICATION'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        multiSelectWithService,
        multiSelect,
        SaveButton,
        ClearFilterButton,
        Treeselect
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        userStatuses,
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            userFilterModal: {},
            submitLoading: false,
            statesWithCities,
            timezones,
            filterRoleParameters: {
                with_admin: true,
                all_users: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
        }
    },
    validations: {},
    computed: {
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nTimezone() {
            return this.getI18n(i18nCommon, 'timezone')
        },
        i18nSituation() {
            return this.getI18n(i18nCommon, 'status')
        },
        i18nRole() {
            return this.getI18n('SECURITY', 'TITLES.role')
        },
        i18nStateAndCity() {
            return this.getI18n(i18nCommon, 'state_and_city')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.userFilterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.userFilterModal = Object.assign(this.userFilterModal, {
                timezone: null,
                active: null,
                roles: [],
                statesAndCities: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.userFilterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
