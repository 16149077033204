import BaseService from '@src/services/BaseService'

class WorkScheduleItemService extends BaseService {
    constructor() {
        super('work-schedule-items')
    }

    clone(params) {
        return this.axios.post(`${this.getUrl()}/clone`, params)
    }

    deleteMultiple(params) {
        return this.axios.post(`${this.getUrl()}/delete-multiple`, params)
    }

    deleteSelected(params) {
        return this.axios.post(`${this.getUrl()}/delete-selected`, params)
    }
}
export default new WorkScheduleItemService()
