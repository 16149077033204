import BaseService from '@src/services/BaseService'
import * as storeFile from "@state/store";

class ImportJobService extends BaseService {
    constructor() {
        super('import-jobs')
    }

    getUrl() {
        return `${process.env.VUE_APP_API_ENDPOINT}/${this.filterTenant ? `tenant/${storeFile.default.getters['auth/tenantId']}/`: ''}${this.urlRoute}`
    }

    createCsv(csv) {
        const formData = new FormData()
        formData.append('import_file', csv.file)
        formData.append('import_type', csv.type)
        formData.append('import_delimiter', csv.delimiter)
        for (var key in csv.fields_mapped) {
            formData.append(
                `fields[${key}]`,
                csv.fields_mapped[key] !== null ? csv.fields_mapped[key] : ''
            )
        }
        formData.append('has_headers', csv.has_headers ? 1 : 0)
        return this.axios.post(`${this.getUrl()}/create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }
}

export default new ImportJobService()
