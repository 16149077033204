<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import UsersService from '@src/services/UsersService.js'
import Layout from '@layouts/main.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import userFilterModal from '@views/users/userFilterModal.vue'
import FilterBar from '@src/components/FilterBar'
import workScheduleFrequencies from '@src/mixins/workScheduleFrequencies'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import importModal from '@views/importJob/importJobModal'
import workScheduleDeletePeriodModal from '@views/workSchedule/workScheduleDeletePeriodModal.vue'
import DeletePeriodLabelButton from '@/src/components/DeletePeriodLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import priorityTypes from '@src/mixins/priorityTypes.vue'
import userFields from '@src/fields/userFields.vue'
import { debounce } from 'lodash'

const i18nKey = 'WORKSCHEDULES'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        userFilterModal,
        FilterBar,
        importModal,
        workScheduleDeletePeriodModal,
        DeletePeriodLabelButton,
        ImportButton,
        ExportButton,
        FilterLabelButton,
    },
    mixins: [
        pagination,
        swalFeedback,
        workScheduleFrequencies,
        vuetableFormatters,
        filterVuetable,
        priorityTypes,
        userFields,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            modalIsEdit: false,
            modal: {
                id: null,
                pos_id: null,
                pos: {
                    id: 0,
                    name: '',
                },
                user_id: null,
                user: {
                    id: 0,
                    name: '',
                },
                mix_id: null,
                mix: {
                    id: 0,
                    name: '',
                    business_unit: {
                        id: 0,
                        name: '',
                    },
                    products: [],
                },
                start_date: null,
                end_date: null,
                priority_order: '',
                human_readable_frequencies: [],
            },
            additionalParameters: {
                with: ['state', 'city', 'roles', 'workday'],
            },
            data: [],
            perPage: 10,
            filterText: '',
            filters: {
                timezone: null,
                active: null,
                roles: [],
                statesAndCities: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.i18nWorkSchedule,
                    active: true,
                },
                {
                    text: this.i18nUsers,
                    active: true,
                },
            ]
        },
        i18nCalendar() {
            return this.getI18n(i18nCommon, 'calendar')
        },
        i18nUsers() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        i18nWorkSchedule() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.work_schedule',
                modifier: 2,
            })
        },
        i18nNewWorkSchedule() {
            return this.getI18n(i18nKey, 'BUTTONS.new_work_schedule')
        },
        fields() {
            return this.userFields.filter(
                (field) => (field.name !== 'long_name') && (field.name !== 'timezone') && (field.name !== 'active') &&
                    (field.name !== 'active') && (field.name !== 'app_version') && (field.name !== 'platform') &&
                    (field.name !== 'updated_at') && (typeof field.name !== 'object')
            )
        },
    },

    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                search: '',
                with: ['state', 'city', 'roles', 'workday'],
            }

            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText};email:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        createAdditionalParameters() {
            let activeSearch = null
            if (this.filters.active && (this.filters.active.value === 'active' || this.filters.active.value === 'not_active')) {
                activeSearch = String(this.filters.active.value === 'active')
            }

            const search = this.createSearch({
                timezone: this.filters.timezone
                    ? this.filters.timezone.id
                    : null,
                active: activeSearch,
            })

            return this.formatParameters({
                with: ['state', 'city', 'roles', 'workday'],
                all_users: true,
                search,
                searchJoin: search.search(';') > 0 ? 'and' : '',
                city_id: this.filters.statesAndCities.filter(
                    (id) => typeof id === 'number'
                ),
                state: this.filters.statesAndCities.filter(
                    (id) => typeof id === 'string'
                ),
                role_id: this.filters.roles.map((role) => role.id),
                work_leave: this.filters.active && this.filters.active.value === 'work_leave' ? true : null,
            })
        },

        fetch(url, params) {
            return UsersService.fetchVuetable(url, params)
        },

        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },

        openWorkScheduleForUser(user) {
            this.$router.push({ name: 'workScheduleForUser', params: { id: user.id } })
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },
        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.userFilterModal.showModal(this.filters)
            )
        },
        handleDeletePeriodModal() {
            this.$refs.workScheduleDeletePeriodModal.showModal()
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.work_schedule',
                modifier: 2,
            }),
        }
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText" />
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <delete-period-label-button @onClick="handleDeletePeriodModal" />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="work_schedule"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="`${i18nUsers} (${i18nWorkSchedule})`" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="status" slot-scope="props">
                            <b-badge
                                v-if="props.rowData.is_work_leave"
                                variant="danger"
                            >
                                {{ getI18n(i18nCommon, 'license') }}
                            </b-badge>
                            <b-badge
                                v-else-if="props.rowData.active"
                                variant="primary"
                            >
                                {{ getI18n(i18nCommon, 'active') }}
                            </b-badge>
                            <b-badge v-else variant="secondary">
                                {{ getI18n(i18nCommon, 'not_active') }}
                            </b-badge>
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <a
                                    href="javascript: void(0);"
                                    class="action-icon"
                                    :title="i18nCalendar"
                                    @click="openWorkScheduleForUser(props.rowData)">
                                    <i class="fe-calendar icon-blue"/>
                                </a>
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <import-modal
            ref="importModal"
            :csv-type="'work_schedule'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TITLES.work_schedule',
                    modifier: 2,
                })
            "
        />
        <user-filter-modal
            ref="userFilterModal"
            :handle-submit="filter"
        />
        <work-schedule-delete-period-modal
            ref="workScheduleDeletePeriodModal"
        />
    </Layout>
</template>
