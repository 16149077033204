<template>
    <div class="vue-csv-mapping">
        <b-form ref="formMap">
            <topic-header
                :caption="getI18n('COMMON', 'mapping')"
                icon="fe-database"
            />
            <vuetable
                ref="mapTable"
                :fields="fields"
                :api-mode="false"
                :data="fieldsToMap"
                class="table table-centered table-hover mb-0"
            >
                <template slot="csvColumn" slot-scope="props">
                    <b-form-select
                        v-model="fieldsMapped[props.rowData.key]"
                        :options="options(props.rowData.required)"
                        required
                        class="input-pdv-blue"
                    />
                </template>
            </vuetable>
        </b-form>
    </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import TopicHeader from '@src/components/TopicHeader.vue'

export default {
    components: {
        Vuetable,
        TopicHeader,
    },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        firstRow: {
            type: Array,
            required: true,
        },
        fieldsToMap: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            fields: [
                {
                    name: 'label',
                    title: this.getI18n('COMMON', 'field'),
                },
                {
                    name: 'csvColumn',
                    title: this.getI18n('COMMON', 'csv_column'),
                },
            ],
        }
    },

    computed: {
        fieldsMapped: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
            },
        },
    },
    methods: {
        validateForm() {
            this.$refs.formMap.reportValidity()
            return this.$refs.formMap.checkValidity()
        },
        options(required) {
            const options = this.firstRow.map((value, key) => {
                return { value: key, text: value }
            })
            if (!required) {
                options.push({
                    value: null,
                    text: this.getI18n('COMMON', 'ignore'),
                })
            }
            return options
        },

        refreshTable() {
            this.$refs.mapTable.refresh()
        },
    },
}
</script>
