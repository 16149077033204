<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-blue mr-1"
        href="javascript: void(0);"
        @click="$emit('onClick')"
    >
        <i class="fe-filter mr-1"></i>
        {{  getI18nModified({ prefix: 'COMMON', suffix: 'filter', modifier: 2 }) }}
    </button>
</template>

<script>
export default {}
</script>
