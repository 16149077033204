<template>
    <b-button
        block
        :class="[childClass, 'btn btn-pdv-gradient-blue-transparent']"
        :disabled="busy || disabled"
        @click="$emit('onClick')"
    >
        <template v-if="busy">
            <b-spinner small></b-spinner>
        </template>
        <template v-else>{{ getI18n('COMMON.back') }}</template>
    </b-button>
</template>

<script>
export default {
    props: {
        childClass: {
            type: String,
            required: false,
            default: ''
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
}
</script>
