<script>
const i18nKey = 'WORKSCHEDULES'

export default {
    data() {
        return {
            priorityTypes: [
                {
                    text: this.priorityTypeToText('1'),
                    value: 1,
                },
                {
                    text: this.priorityTypeToText('2'),
                    value: 2,
                },
                {
                    text: this.priorityTypeToText('3'),
                    value: 3,
                },
            ],
        }
    },

    methods: {
        priorityTypeToText(value) {
            return this.getI18n(i18nKey, `TYPES.${value}`)
        },
    },
}
</script>
