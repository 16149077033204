<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalFilterClone"
        :title="i18nTitleModal"
        :in-editing="true"
        size="lg"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nTitleModal"
                    :description="descriptionConfirmationModal"
                    :operation="'delete_all'"
                />
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nPeriod"
                            class="required label-pdv"
                            label-for="modal-period-date"
                            :invalid-feedback="getI18n('ERROR_CODES', 'invalid_period')"
                        >
                            <date-range-picker
                                :child-class="validationClass($v.modal.start_date)"
                                :value.sync="dateRange"
                                :min-date="tomorrowDate"
                                :disable-clean-button="true"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group
                            :label="i18nAllUsers"
                            label-for="modal-all-users"
                            class="label-pdv"
                        >
                            <b-form-checkbox
                                id="modal-all-users"
                                v-model="allUsers"
                                name="modal-all-users"
                                size="lg"
                                switch
                                :disabled="submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nTeams"
                            label-for="modal-teams"
                            class="label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_team')"
                        >
                            <multiSelectWithService
                                :id="'modal-teams'"
                                ref="teamMultiSelect"
                                v-model="modal.teams"
                                :v="$v.modal.teams"
                                :class="validationClass($v.modal.teams)"
                                :service="'teams'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                                :disabled="modal.users.length > 0 || allUsers || submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nUsers"
                            label-for="modal-users"
                            class="label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_user')"
                        >
                            <multiSelectWithService
                                :id="'modal-users'"
                                ref="userMultiSelect"
                                v-model="modal.users"
                                :v="$v.modal.users"
                                :class="validationClass($v.modal.users)"
                                :service="'users'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="usersParameters"
                                :disabled="modal.teams.length > 0 || allUsers || submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div class="col text-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <delete-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    :title="getI18n('COMMON.delete')"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { required, requiredIf, numeric } from 'vuelidate/lib/validators'

import swalFeedback from '@src/mixins/swalFeedback'

import WorkScheduleItemService from '@src/services/WorkScheduleItemService'

import validationMixin from '@src/mixins/validation'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService'
import FormModal from '@components/FormModal'
import filterVuetable from '@src/mixins/filterVuetable'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import CancelButton from '@/src/components/CancelButton.vue'
import DeleteButton from '@/src/components/DeleteButton'
import moment from 'moment';

const i18nCommon = 'COMMON'
const i18nKey = 'WORKSCHEDULES'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        DateRangePicker,
        multiSelectWithService,
        CancelButton,
        DeleteButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        filterVuetable,
        vuetableFormatters,
    ],
    data() {
        return {
            i18nCommon,
            i18nKey,
            submitLoading: false,
            descriptionConfirmationModal: '',
            allUsers: false,
            dateRange: {},
            modal: {
                start_date: null,
                end_date: null,
                users: [],
                teams: [],
            },
            parameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        modal: {
            start_date: {
                required,
                minDate(value, model) {
                    return value >= moment().startOf('day').toDate()
                },
                beforeEndDate(value, model) {
                    return value <= model.end_date
                },
            },
            end_date: {
                required,
                afterStartDate(value, model) {
                    return value >= model.start_date
                },
            },
            teams: {
                required: requiredIf(function (model) {
                    return !this.allUsers && model.users.length === 0
                }),
                // minLength: minLength(1),
                $each: {
                    id: {
                        required,
                        numeric,
                    },
                },
            },
            users: {
                required: requiredIf(function (model) {
                    return !this.allUsers && model.teams.length === 0
                }),
                // minLength: minLength(1),
                $each: {
                    id: {
                        required,
                        numeric,
                    },
                },
            },
        },
    },
    computed: {
        i18nTitleModal() {
            return this.getI18n(i18nCommon, 'delete_period')
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nWorkSchedules() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.work_schedule',
                modifier: 2,
            })
        },
        i18nUsers() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        i18nTeams() {
            return this.getI18nModified({
                prefix: 'TEAMS',
                suffix: 'TITLES.team',
                modifier: 2,
            })
        },
        i18nAllUsers() {
            return `${this.getI18n(i18nCommon, 'all')} ${this.i18nUsers}`
        },
        tomorrowDate() {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            return tomorrow;
        }
    },
    watch: {
        dateRange: {
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.modal.start_date = newValue.start
                    this.modal.end_date = newValue.end

                    this.$v.modal.start_date.$touch()
                    this.$v.modal.end_date.$touch()
                }
                else {
                    this.modal.start_date = null
                    this.modal.end_date = null
                }
            },
        },
        allUsers(value) {
            if (value) {
                this.modal.users = [];
                this.modal.teams = [];
            }
        },
    },
    methods: {
        showModal() {
            this.modal = {
                start_date: null,
                end_date: null,
                users: [],
                teams: [],
            }
            this.allUsers = false
            this.dateRange = {
                start: moment().startOf('day').add(1, 'days').toDate(),
                end: moment().startOf('day').add(31, 'days').toDate(),
            }
            this.submitLoading = false
            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            Object.assign(this.modal,
            {
                start_date: null,
                end_date: null,
            })
            this.resetValidation()
        },

        handleCancel() {
            this.handleClose(true)
        },

        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modal.$touch()
            if (!this.$v.modal.$invalid && !this.submitLoading) {
                this.descriptionConfirmationModal = `${this.getI18n(i18nCommon, 'delete_all')} ${this.i18nWorkSchedules.toLowerCase()} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.formatDate(this.modal.start_date)} à ${this.formatDate(this.modal.end_date)}`
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const data = {
                all_users: this.allUsers,
                team_id: this.modal.teams.map((team) => team.id),
                user_id: this.modal.users.map((user) => user.id),
                start: moment(this.modal.start_date).format('YYYY-MM-DD'),
                end: moment(this.modal.end_date).format('YYYY-MM-DD'),
            }

            const operation = WorkScheduleItemService.deleteSelected(data)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nWorkSchedules, 'deleted')
            } else if (statusCode === '422') {
                this.unsuccessfulOperation(createResponse.data.errors)
            } else {
                this.warningFeedback(this.i18nTitleModal, createResponse.data.message)
            }

            this.submitLoading = false
        },
    },
}
</script>
