<script>
const i18nCommon = 'COMMON'

export default {
    data() {
        return {
            userStatuses: [
                {
                    value: 'active',
                    text: this.getI18n(i18nCommon, 'active')
                },
                {
                    value: 'not_active',
                    text: this.getI18n(i18nCommon, 'not_active'),
                },
                {
                    value: 'work_leave',
                    text: this.getI18n(i18nCommon, 'license')
                },
            ],
        }
    },
}
</script>
