<script>
import { get, map, forEach, drop, set } from 'lodash'
import Papa from 'papaparse'

export default {
    methods: {
        createFieldsToMap(fields) {
            return map(fields, (value, key) => {
                return {
                    key: key,
                    label: value.label,
                    required: value.required,
                }
            })
        },
        mapByName(firstLine, csvFields, fieldsToMap) {
            csvFields.forEach((field) => {
                firstLine.forEach((columnName, index) => {
                    if (
                        field.key.toLowerCase().trim() ===
                        columnName.toLowerCase().trim()
                    ) {
                        this.$set(fieldsToMap, field.key, index)
                    }
                })
            })
        },
        mapJson(csvValues, fieldsMapped, hasHeaders) {
            const csv = hasHeaders ? drop(csvValues) : csvValues
            return map(csv, (row) => {
                const newRow = {}
                forEach(fieldsMapped, (column, field) => {
                    const value = get(row, column)
                    if (value) {
                        set(newRow, field, get(row, column))
                    } else {
                        set(newRow, field, null)
                    }
                })
                return newRow
            })
        },
        loadCsv(file, fileType = 'UTF-8') {
            return new Promise((resolve, reject) => {
                let csvPreview = null
                let fullCsv = null
                let delimiter = ','
                this.readCsv(file, fileType, (output) => {
                    const preview = Papa.parse(output, {
                        preview: 10,
                        skipEmptyLines: true,
                    })
                    csvPreview = get(preview, 'data')
                    delimiter = get(preview, 'meta.delimiter')
                    if(delimiter === '\t'){
                        delimiter = ','
                    }
                    fullCsv = get(
                        Papa.parse(output, { skipEmptyLines: true }),
                        'data'
                    )
                }).then(() => {
                    if (!csvPreview) {
                        reject(new Error('csv_not_read'))
                    }
                    resolve({ csvPreview, fullCsv, delimiter })
                })
            })
        },
        readCsv(file, fileType, callback) {
            return new Promise((resolve, reject) => {
                if (file) {
                    const reader = new FileReader()
                    reader.readAsText(file, fileType)
                    reader.onload = function (evt) {
                        callback(evt.target.result)
                        resolve(true)
                    }
                    reader.onerror = function () {
                        reject(new Error('bad_file'))
                    }
                }
            })
        },
    },
}
</script>
